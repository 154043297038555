import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, type TagType } from '@volvo/vce-package-site-uikit';
import type {
  FragmentPointOfInterestForSiteMaterialFlow,
  FragmentSiteMaterialFlowWithMetricData,
} from '../../../../gql-types/generated-types-super-graph';
import { TabKeys, type Tab } from '../types';

type Tag = { timestamp: string; type: TagType };

type Props = {
  materialFlowData: FragmentSiteMaterialFlowWithMetricData[];
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  lastFetch?: number;
  setOpenTab: (tab: Tab) => void;
  openTab: Tab;
};

export const Header: FC<Props> = ({
  materialFlowData,
  pointOfInterestData,
  lastFetch,
  setOpenTab,
  openTab,
}) => {
  const { t } = useTranslation();

  const onClick = (item: Tab) => {
    setOpenTab(item);
  };

  const source: Tag = useMemo(() => {
    const timestamp = DateTime.fromMillis(
      Math.max(
        ...[...materialFlowData, ...pointOfInterestData]
          .map((i) => i.siteMetricDefinitions)
          .flat()
          .map((j) =>
            j.currentSiteMetricData?.originCreatedAt
              ? DateTime.fromISO(j.currentSiteMetricData.originCreatedAt).toMillis()
              : null,
          )
          .filter((i) => isNumber(i)),
      ),
    );

    if (!timestamp.isValid) {
      return { timestamp: t('N/A'), type: 'warning' };
    }

    const isToday = timestamp.hasSame(DateTime.local(), 'day');
    const formatted = timestamp.toFormat(isToday ? 'HH:mm' : 'dd/MM HH:mm');

    return {
      timestamp: `${isToday ? t('Today') : ''} ${formatted}`,
      type: DateTime.local().diff(timestamp).as('minutes') < 10 ? 'success' : 'warning',
    };
  }, [materialFlowData, pointOfInterestData, t]);

  const fetch: Tag = useMemo(() => {
    if (!lastFetch) {
      return { timestamp: t('N/A'), type: 'warning' };
    }

    const isToday = DateTime.fromMillis(lastFetch).hasSame(DateTime.local(), 'day');
    const formatted = DateTime.fromMillis(lastFetch).toFormat(isToday ? 'HH:mm' : 'dd/MM HH:mm');

    return {
      timestamp: `${isToday ? t('Today') : ''} ${formatted}`,
      type:
        lastFetch && DateTime.local().diff(DateTime.fromMillis(lastFetch)).as('minutes') < 10
          ? 'success'
          : 'warning',
    };
  }, [lastFetch, t]);

  return (
    <PageHeader
      title={t('production-data.process')}
      tags={[
        {
          type: fetch.type,
          text: t('production-data.page-updated') + fetch.timestamp,
        },
        {
          type: source.type,
          text: t('production-data.source-updated') + source.timestamp,
        },
      ]}
      tabs={Object.keys(TabKeys).map((key) => ({
        key,
        title: t(`production-data.tabs.${key}`),
        onClick: () => onClick(key as keyof typeof TabKeys),
        active: key === openTab,
      }))}
    />
  );
};
