import { isEmpty } from 'lodash';
import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSiteConfigContext } from '../../../../../../context/site-config/SiteConfigContext';
import {
  MetricDefinitionType,
  type FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
} from '../../../../../../gql-types/generated-types-super-graph';
import { TabKeys, type Tab } from '../../../types';
import { DataCell } from '../../data-cell/DataCell';
import { getMetric, getPoiName } from '../../helpers';
import { StatusIcon } from '../../status-icon/StatusIcon';
import { Row } from '../../styles';

type Props = {
  siteMaterialFlow: FragmentSiteMaterialFlowWithMetricData;
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  tab: Tab;
};

export const SensorRow: FC<Props> = ({ siteMaterialFlow, pointOfInterestData, tab }) => {
  const { t } = useTranslation();
  const { units } = useSiteConfigContext();

  const statusIndicator = siteMaterialFlow.siteMetricDefinitions.find(
    ({ type }) => type === MetricDefinitionType.StatusIndicator,
  );

  const massFlowRate = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [MetricDefinitionType.MassFlowRate, MetricDefinitionType.FlowRateMass],
    units,
  );

  const level = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [
      MetricDefinitionType.LevelVolume,
      MetricDefinitionType.LevelPercent,
      MetricDefinitionType.LevelMass,
    ],
    units,
  );

  const producedMass = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [MetricDefinitionType.ProducedMass],
    units,
  );

  const material = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [MetricDefinitionType.Material],
    units,
  );

  const materialString = useMemo(() => {
    if (tab !== TabKeys.CRUSHERS) {
      return '';
    }
    if (
      !material.metricDefinition?.materialVariant &&
      !isEmpty(material.metricDefinition?.currentSiteMetricData?.value)
    ) {
      return `${t('production-data.unmapped')} (${material.metricDefinition?.currentSiteMetricData?.value})`;
    }
    if (material.metricDefinition?.materialVariant) {
      if (material.metricDefinition?.currentSiteMetricData?.value) {
        return `${material.metricDefinition.materialVariant.name}, ${material.metricDefinition.materialVariant.materialType.name}, ${material.metricDefinition.materialVariant.materialType.materialFamily.name} (${material.metricDefinition?.currentSiteMetricData?.value})`;
      }
      return `${material.metricDefinition.materialVariant.name}, ${material.metricDefinition.materialVariant.materialType.name}, ${material.metricDefinition.materialVariant.materialType.materialFamily.name}`;
    }
    if (isEmpty(material.metricDefinition?.currentSiteMetricData?.value)) {
      return t('production-data.no-value');
    }
  }, [material.metricDefinition, t, tab]);

  return (
    <Row>
      <DataCell>{siteMaterialFlow.name} </DataCell>
      {tab !== TabKeys.CRUSHERS && (
        <DataCell>{getPoiName(siteMaterialFlow, pointOfInterestData)}</DataCell>
      )}
      <DataCell metricDefinition={statusIndicator} tooltip>
        {statusIndicator ? (
          <StatusIcon status={statusIndicator?.currentSiteMetricData?.statusIndicator} />
        ) : null}
      </DataCell>
      {tab !== TabKeys.FUEL_STATIONS && (
        <DataCell metricDefinition={massFlowRate.metricDefinition} tooltip>
          {massFlowRate.value}
        </DataCell>
      )}
      {tab !== TabKeys.CRUSHERS && (
        <DataCell metricDefinition={level.metricDefinition} tooltip>
          {level.value}
        </DataCell>
      )}
      {tab === TabKeys.CRUSHERS ? (
        <>
          <DataCell metricDefinition={material.metricDefinition} tooltip>
            {materialString}
          </DataCell>
          <DataCell metricDefinition={producedMass.metricDefinition} tooltip>
            {producedMass.value}
          </DataCell>
        </>
      ) : null}
    </Row>
  );
};
