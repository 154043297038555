import { isEmpty } from 'lodash';
import { useEffect, useState, type FC } from 'react';
import { CenteredSpinner } from '../../../components/common/loading/CenteredSpinner';
import {
  FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
} from '../../../gql-types/generated-types-super-graph';
import { Header } from './header/Header';
import { Container } from './styles';
import { Table } from './table/Table';
import { TabKeys, type Tab } from './types';

type Props = {
  materialFlowData: FragmentSiteMaterialFlowWithMetricData[];
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  loading: boolean;
  lastFetch?: number;
};

export const Content: FC<Props> = ({
  materialFlowData,
  pointOfInterestData,
  loading,
  lastFetch,
}) => {
  const [openTab, setOpenTab] = useState<Tab>(
    (localStorage.getItem('process_tab') as Tab) ?? TabKeys.FACTORIES_AND_SILOS,
  );

  useEffect(() => {
    localStorage.setItem('process_tab', openTab.toString());
  }, [openTab]);

  return (
    <>
      <Header
        materialFlowData={materialFlowData}
        pointOfInterestData={pointOfInterestData}
        lastFetch={lastFetch}
        setOpenTab={setOpenTab}
        openTab={openTab}
      />
      <Container>
        {isEmpty(materialFlowData) && isEmpty(pointOfInterestData) && loading ? null : (
          <Table
            materialFlowData={materialFlowData}
            pointOfInterestData={pointOfInterestData}
            tab={openTab}
          />
        )}
        {loading && <CenteredSpinner style={{ position: 'absolute', top: '110px' }} />}
      </Container>
    </>
  );
};
